
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CurrencyIcon extends Vue {
  @Prop(String) readonly path: string | undefined;
  @Prop(Number) readonly index: number | undefined;

  get iconColor(): string {
    const id = this.index ? this.index % 12 : 0
    return settings.company.companyUISettings.dashboard.rainbow[id] || '#000000'
  }

  get iconImage(): Array<string> {
    if (this.path) {
      const image = this.path.split('\n').filter(s => s.trim() !== '')
      if (image && image.length > 0) {
        return image
      }
    }

    return []
  }
}
