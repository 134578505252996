
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseCard extends Vue {
  get cardClass(): string {
    let cls = '';

    cls += 'rounded-' + settings.company.companyUISettings.card.rounded + ' '

    return cls;
  }

  get elevation() {
    return settings.company.companyUISettings.card.elevation + ' '
  }

  click(event: any) {
    this.$emit('click', event);
  }
}
