
import $ph from '@/plugins/phoenix'
import { BaseAccess } from '@/plugins/phoenix/types';
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop(String) readonly access: BaseAccess | Array<BaseAccess> | undefined;
  @Prop(String) readonly caption: string | undefined;
  @Prop(String) readonly tooltip: string | undefined;
  @Prop(String) readonly glyph: string | undefined;
  @Prop(String) readonly collapsedGlyph: string | undefined;
  @Prop(String) readonly glyphSize: string | undefined;
  @Prop(String) readonly glyphColor: string | undefined;
  @Prop(String) readonly color: string | undefined;
  @Prop(String) readonly textColor: string | undefined;
  @Prop(Boolean) readonly disabled: boolean | undefined;
  @Prop(Boolean) readonly processing: boolean | undefined;
  @Prop(Boolean) readonly toolbar: boolean | undefined;
  @Prop(String) readonly collapsed: string | undefined;
  @Prop(String) readonly extraClass: string | undefined;
  @Prop(Boolean) readonly link: boolean | undefined;
  @Prop(String) readonly href: string | undefined;
  @Prop(String) readonly target: string | undefined;
  @Prop(String) readonly to: string | undefined;

  @Prop() readonly tooltipTop: any;
  @Prop() readonly tooltipRight: any;
  @Prop() readonly tooltipLeft: any;
  @Prop() readonly tooltipBottom: any;

  @Prop() readonly outlined: any;
  @Prop() readonly depressed: any;
  @Prop() readonly plain: any;
  @Prop() readonly rounded: any;
  @Prop() readonly text: any;
  @Prop() readonly block: any;
  @Prop() readonly icon: any;

  @Prop(String) readonly height: string | undefined;
  @Prop(String) readonly minHeight: string | undefined;
  @Prop(String) readonly maxHeight: string | undefined;
  @Prop(String) readonly width: string | undefined;
  @Prop(String) readonly minWidth: string | undefined;
  @Prop(String) readonly maxWidth: string | undefined;
  @Prop() readonly elevation: any;

  @Prop() readonly left: any | undefined;
  @Prop() readonly right: any | undefined;
  @Prop() readonly middle: any | undefined;

  @Prop() readonly xLarge: any | undefined;
  @Prop() readonly large: any | undefined;
  @Prop() readonly small: any | undefined;
  @Prop() readonly medium: any | undefined;
  @Prop() readonly xSmall: any | undefined;

  get isDisabled(): boolean {
    if (this.access) {
      return (this.disabled || false) || !$ph.access(this.access);
    } else {
      return this.disabled || false;
    }
  }

  get loaderSize(): string {
    if (this.xLarge) {
      return '40'
    } else if (this.large) {
      return '30'
    } else if (this.small) {
      return '10'
    } else if (this.xSmall) {
      return '10'
    } else {
      return '20'
    }
  }

  hasClass(value: string): boolean {
    if (this.toolbar) {
      return settings.company.companyUISettings.tool.class.includes(value);
    } else {
      return settings.company.companyUISettings.button.class.includes(value);
    }
  }

  bool(value: any): boolean {
    return value === '' || String(value) === 'true';
  }

  get elevationSize() {
    return this.elevation === null || this.elevation === undefined
      ? (this.toolbar ? settings.company.companyUISettings.tool.elevation : settings.company.companyUISettings.button.elevation)
      : Number(this.elevation);
  }

  get isOutlined() {
    if (this.isText) {
      return false
    } else {
      return this.outlined === null || this.outlined === undefined ? this.hasClass('outlined') : this.bool(this.outlined);
    }
  }

  get isDepressed() {
    if (this.isIconic) {
      return false
    } else {
      return this.depressed === null || this.depressed === undefined ? this.hasClass('depressed') : this.bool(this.depressed);
    }
  }

  get isText() {
    if (this.isIconic) {
      return false
    } else {
      return this.text === null || this.text === undefined ? this.hasClass('text') : this.bool(this.text);
    }
  }

  get isRounded() {
    if (this.isIconic || this.middle === true || this.middle === '') {
      return false
    } else {
      return this.rounded === null || this.rounded === undefined ? this.hasClass('rounded') : this.bool(this.rounded);
    }
  }

  get isPlain() {
    if (this.isIconic) {
      return false
    } else {
      return this.plain === null || this.plain === undefined ? this.hasClass('plain') : this.bool(this.plain);
    }
  }

  get btnclass() {
    let cls = '';

    let rounded = 'rounded-'
    if (this.isRounded) {
      if (this.middle === true || this.middle === '') {
        rounded = 'rounded-l-0 rounded-r-0 dummy-'
      } else if (this.left === true || this.left === '') {
        rounded = 'rounded-r-0 rounded-l-pill dummy-'
      } else if (this.right === true || this.right === '') {
        rounded = 'rounded-l-0 rounded-r-pill dummy-'
      } else {
        rounded = 'dummy-'
      }
    } else {
      if (this.middle === true || this.middle === '') {
        rounded = 'rounded-l-0 rounded-r-0 dummy-'
      } else if (this.left === true || this.left === '') {
        rounded = 'rounded-r-0 rounded-l-'
      } else if (this.right === true || this.right === '') {
        rounded = 'rounded-l-0 rounded-r-'
      } else {
        rounded = 'rounded-'
      }
    }

    if (this.toolbar) {
      cls += rounded + settings.company.companyUISettings.tool.rounded + ' '
    } else {
      cls += rounded + settings.company.companyUISettings.button.rounded + ' '
    }

    if (this.toolbar) {
      cls += 'elevate-' + settings.company.companyUISettings.tool.elevation + ' '
    } else {
      cls += 'elevate-' + settings.company.companyUISettings.button.elevation + ' '
    }

    cls += this.extraClass || ''

    return cls;
  }

  get btnstyle() {
    let style = '';
    if (this.toolbar && !this.caption) {
      if (!this.minWidth) {
        if (this.isXLarge) {
          style += 'min-width: 52px; '
        } else if (this.isLarge) {
          style += 'min-width: 44px; '
        } else if (this.isSmall) {
          style += 'min-width: 28px; '
        } else if (this.isXSmall) {
          style += 'min-width: 20px; '
        } else {
          style += 'min-width: 36px; '
        }
      }
      style += 'padding-left: 0px; '
      style += 'padding-right: 0px; '
    }

    return style;
  }

  get textClass() {
    let cls = ''

    if (this.toolbar) {
      if (this.isXSmall) {
        cls += 'wml-small-tool-button '
      } else {
        cls += 'wml-tool-button '
      }

      if (this.textColor !== undefined) {
        cls += this.textColor + '--text '
      } else if (!this.isText && !this.isOutlined) {
        if (this.color && this.color !== '') {
          if (this.color === 'primary') {
            cls += 'ontool-primary--text '
          } else {
            cls += 'ontool-styled--text '
          }
        } else {
          cls += 'ontool--text '
        }
      } else if (this.isOutlined) {
        cls += 'primary--text '
      }
    } else {
      if (this.isXSmall) {
        cls += 'wml-small-button '
      } else {
        cls += 'wml-button '
      }

      if (this.textColor !== undefined) {
        cls += this.textColor + '--text '
      } else if (!this.isText && !this.isOutlined) {
        if (this.color && this.color !== '') {
          if (this.color === 'primary') {
            cls += 'onbutton-primary--text '
          } else {
            cls += 'onbutton-styled--text '
          }
        } else {
          cls += 'onbutton--text '
        }
      } else if (this.isOutlined) {
        cls += 'primary--text '
      }
    }

    return cls;
  }

  get iconClass() {
    let cls = ''
    if (!this.isIconic && this.caption && this.caption !== '') {
      cls += 'mr-2 '
    }

    if (!this.glyphColor || this.glyphColor === '') {
      if (this.toolbar) {
        if (!this.isText && !this.isOutlined) {
          if (this.color && this.color !== '') {
            if (this.color === 'primary') {
              cls += 'ontool-primary--text '
            } else {
              cls += 'ontool-styled--text '
            }
          } else {
            cls += 'ontool--text '
          }
        }
      } else {
        if (!this.isText && !this.isOutlined) {
          if (this.color && this.color !== '') {
            if (this.color === 'primary') {
              cls += 'onbutton-primary--text '
            } else {
              cls += 'onbutton-styled--text '
            }
          } else {
            cls += 'onbutton--text '
          }
        }
      }
    }

    return cls
  }

  get isCollapsed() {
    let collapse = false;

    if (this.collapsed) {
      if (this.collapsed === 'xs') {
        collapse = this.$vuetify.breakpoint.xsOnly;
      } else if (this.collapsed === '' || this.collapsed === 'sm') {
        collapse = this.$vuetify.breakpoint.smAndDown;
      } else if (this.collapsed === 'md') {
        collapse = this.$vuetify.breakpoint.mdAndDown;
      } else if (this.collapsed === 'lg') {
        collapse = this.$vuetify.breakpoint.lgAndDown;
      } else if (this.collapsed === 'xl') {
        collapse = true;
      }
    }

    return collapse;
  }

  get isIconic() {
    return this.icon === '' || String(this.icon) === 'true' || this.isCollapsed;
  }

  get isBlock() {
    return (this.block === '' || String(this.block) === 'true') && !this.isIconic;
  }

  get isGlyphXLarge() {
    if (this.toolbar) {
      return this.glyphSize === 'x-large' || (!this.glyphSize && settings.company.companyUISettings.tool.glyph === 'x-large')
    } else {
      return this.glyphSize === 'x-large' || (!this.glyphSize && settings.company.companyUISettings.button.glyph === 'x-large')
    }
  }

  get isGlyphLarge() {
    if (this.toolbar) {
      return this.glyphSize === 'large' || (!this.glyphSize && settings.company.companyUISettings.tool.glyph === 'large')
    } else {
      return this.glyphSize === 'large' || (!this.glyphSize && settings.company.companyUISettings.button.glyph === 'large')
    }
  }

  get isGlyphSmall() {
    if (this.toolbar) {
      return this.glyphSize === 'small' || (!this.glyphSize && settings.company.companyUISettings.tool.glyph === 'small')
    } else {
      return this.glyphSize === 'small' || (!this.glyphSize && settings.company.companyUISettings.button.glyph === 'small')
    }
  }

  get isGlyphXSmall() {
    if (this.toolbar) {
      return this.glyphSize === 'x-small' || (!this.glyphSize && settings.company.companyUISettings.tool.glyph === 'x-small')
    } else {
      return this.glyphSize === 'x-small' || (!this.glyphSize && settings.company.companyUISettings.button.glyph === 'x-small')
    }
  }

  get isSizeSet(): boolean {
    return this.xLarge !== undefined || this.large !== undefined || this.small !== undefined || this.xSmall !== undefined || this.medium !== undefined
  }

  get isXLarge() {
    if (this.toolbar) {
      return this.xLarge === '' || String(this.xLarge) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.tool.size === 'x-large')
    } else {
      return this.xLarge === '' || String(this.xLarge) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.button.size === 'x-large')
    }
  }

  get isLarge() {
    if (this.toolbar) {
      return this.large === '' || String(this.large) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.tool.size === 'large')
    } else {
      return this.large === '' || String(this.large) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.button.size === 'large')
    }
  }

  get isSmall() {
    if (this.toolbar) {
      return this.small === '' || String(this.small) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.tool.size === 'small')
    } else {
      return this.small === '' || String(this.small) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.button.size === 'small')
    }
  }

  get isXSmall() {
    if (this.toolbar) {
      return this.xSmall === '' || String(this.xSmall) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.tool.size === 'x-small')
    } else {
      return this.xSmall === '' || String(this.xSmall) === 'true' || (!this.isSizeSet && settings.company.companyUISettings.button.size === 'x-small')
    }
  }

  get btnColor() {
    if (this.toolbar) {
      return this.color || 'tool-def'
    } else {
      return this.color || 'button-def'
    }
  }

  doAction(evt) {
    this.$emit('action', evt)
  }
}
