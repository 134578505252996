
import { system } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppWidgetDisplay, IAppWidget } from '../types';

@Component
export default class BaseCard extends Vue {
  @Prop(String) readonly name: string | undefined;

  get widget(): IAppWidget | null {
    if (this.name) {
      return system.widgetsIndex[this.name] || null
    }
    return null
  }

  get settings(): any {
    if (this.widget) {
      return this.widget.settings || {}
    }
    return {}
  }

  get smSize() {
    return this.widget ? this.widget.widgetSizeSM : 12
  }

  get mdSize() {
    return this.widget ? this.widget.widgetSizeMD : 12
  }

  get lgSize() {
    return this.widget ? this.widget.widgetSizeLG : 12
  }

  get visible() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.widget !== null && this.widget.widgetDisplaySM !== AppWidgetDisplay.None
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return this.widget !== null && this.widget.widgetDisplayMD !== AppWidgetDisplay.None
    } else if (this.$vuetify.breakpoint.lgAndUp) {
      return this.widget !== null && this.widget.widgetDisplayLG !== AppWidgetDisplay.None
    } else {
      return false;
    }
  }
}
