
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Notification extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined;
  @Prop(String) readonly message: string | undefined;
  @Prop(String) readonly type: string | undefined;
  @Prop(Boolean) readonly dismissible: boolean | undefined;
  @Prop() readonly timeout: any | undefined;

  hasClass(value: string): boolean {
    return settings.company.companyUISettings.notify.class.includes(value);
  }

  get visible() {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get rounded(): string {
    return settings.company.companyUISettings.notify.rounded;
  }

  get elevation(): number {
    return settings.company.companyUISettings.notify.elevation;
  }

  get border(): string {
    return settings.company.companyUISettings.notify.border;
  }

  get text(): boolean {
    return this.hasClass('text')
  }

  get outlined(): boolean {
    return this.hasClass('outlined')
  }

  get coloredBorder(): boolean {
    return this.hasClass('colored-border')
  }
}

