
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseGroup extends Vue {
  @Prop() readonly height: any;
  @Prop() readonly padding: any;
  @Prop() readonly active: any;
  @Prop() readonly flat: any;
  @Prop() readonly hover: any;

  hovered = false

  get borderClass(): string {
    let cls = '';

    let elevation = settings.company.companyUISettings.group.elevation || 0

    if (this.hover === '' || this.hover === 'true' || this.hover === true) {
      if (this.hovered) {
        elevation += 4
      }
    }

    cls += 'rounded-' + settings.company.companyUISettings.group.rounded + ' '
    if (!this.flat) {
      cls += 'elevation-' + elevation + ' '
    }

    return cls;
  }

  get cardClass(): string {
    let cls = '';

    cls += !this.padding || this.padding === '' ? 'pa-4 ' : (this.padding + ' ')
    if (!this.flat) {
      cls += 'rounded-' + settings.company.companyUISettings.group.rounded + ' '
    }

    return cls;
  }

  get isOutlined() {
    return settings.company.companyUISettings.group.outlined
  }

  get outlineBorderColor() {
    if (!this.flat) {
      return this.isOutlined ? 'outline' : 'transparent'
    } else {
      return 'transparent'
    }
  }

  get color() {
    if (!this.flat) {
      return 'group'
    } else {
      return 'transparent'
    }
  }

  doAction(event: any) {
    this.$emit('action', event)
  }
}
