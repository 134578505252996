
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

interface LoaderFrame {
  top: number
  left: number
  width: number
  height: number
}

@Component
export default class Loader extends Vue {
  @Prop(String) readonly width: string | undefined;
  @Prop(String) readonly height: string | undefined;
  @Prop(String) readonly primary: string | undefined;
  @Prop(String) readonly secondary: string | undefined;
  @Prop(String) readonly speed: string | undefined;

  get cssVars() {
    const width = this.width ? Number(this.width) : 48
    const height = this.height ? Number(this.height) : 48

    const frames: Array<LoaderFrame> = []
    if (this.isLoaderBars) {
      const tickWidth = Math.max(Math.round(width / 7), 1)
      const tickLeft = Math.max(Math.round((width - (tickWidth * 3)) / 4), 1)
      const tickHeightMin = Math.round(height - height / 2)
      const tickHeightMax = Math.round(height - height / 6)

      frames.push({
        top: Math.round((height - tickHeightMax) / 2),
        left: tickLeft,
        width: tickWidth,
        height: tickHeightMax,
      })
      frames.push({
        top: Math.round((height - tickHeightMin) / 2),
        left: (tickLeft * 2 + tickWidth),
        width: tickWidth,
        height: tickHeightMin,
      })
      frames.push({
        top: Math.round((height - tickHeightMin) / 2),
        left: (tickLeft * 3 + tickWidth * 2),
        width: tickWidth,
        height: tickHeightMin,
      })
    } else if (this.isLoaderSnake) {
      const tickSize = Math.max(Math.min(Math.round(width / 6), 18), 1)
      const tickTop = Math.round(height / 2 - tickSize / 2)
      const tickLeft = Math.max(Math.round(width / 10), 1)

      frames.push({
        top: tickTop,
        left: tickLeft,
        width: tickSize,
        height: tickSize,
      })
      frames.push({
        top: tickTop,
        left: tickLeft,
        width: tickSize,
        height: tickSize,
      })
      frames.push({
        top: tickTop,
        left: tickLeft * 4,
        width: tickSize,
        height: tickSize,
      })
      frames.push({
        top: tickTop,
        left: tickLeft * 7,
        width: tickSize,
        height: tickSize,
      })
      frames.push({
        top: tickTop,
        left: tickLeft * 3,
        width: tickSize,
        height: tickSize,
      })
    } else if (this.isLoaderComets) {
      const border = Math.max(Math.min(Math.round(width / 15), 6), 1)
      frames.push({
        top: border,
        left: 0,
        width: width - border,
        height: height - border,
      })
    } else if (this.isLoaderPulse) {
      const border = Math.max(Math.min(Math.round(width / 15), 6), 1)
      frames.push({
        top: border,
        left: 0,
        width: width,
        height: height,
      })
    }

    const style = {
      '--loader-width': width + 'px',
      '--loader-height': height + 'px',
      '--loader-center': (width / 2) + 'px',
      '--loader-primary-color': this.$vuetify.theme.currentTheme[this.primary || 'primary'] || this.primary,
      '--loader-secondary-color': this.$vuetify.theme.currentTheme[this.secondary || 'secondary'] || this.secondary,
      '--loader-speed': this.speed || '1s',
    }

    for (let i = 0; i < frames.length; i++) {
      style['--loader-frame-' + i + '-top'] = frames[i].top + 'px'
      style['--loader-frame-' + i + '-left'] = frames[i].left + 'px'
      style['--loader-frame-' + i + '-width'] = frames[i].width + 'px'
      style['--loader-frame-' + i + '-height'] = frames[i].height + 'px'
    }

    return style;
  }

  get isLoaderPulse() {
    return settings.company.companyUISettings.global.loader === 'PULSE'
  }

  get isLoaderComets() {
    return settings.company.companyUISettings.global.loader === 'COMETS'
  }

  get isLoaderHourglass() {
    return settings.company.companyUISettings.global.loader === 'HOURGLASS'
  }

  get isLoaderSnake() {
    return settings.company.companyUISettings.global.loader === 'SNAKE'
  }

  get isLoaderBars() {
    return settings.company.companyUISettings.global.loader === 'BARS'
  }
}
