
import settings from '@/plugins/settings';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseCard extends Vue {
  @Prop() readonly color: any;
  @Prop() readonly border: any;

  get cardClass(): string {
    let cls = '';
    cls += 'rounded-' + settings.company.companyUISettings.group.rounded + ' '
    return cls;
  }

  get alertClass(): string {
    let cls = 'mb-0 pa-0 ';

    cls += 'rounded-' + settings.company.companyUISettings.group.rounded + ' '
    cls += 'elevation-' + settings.company.companyUISettings.group.elevation + ' '

    return cls;
  }
}
